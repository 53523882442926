// 导入vue和vue-router
import Vue from 'vue'
import Router from 'vue-router'

// 加载路由插件
Vue.use(Router)
// 导出路由
export default new Router({
  // 路由规则
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('../pages/index.vue') 
    },
    {
      path: '/index1',
      name: 'index1',
      component: () => import('../pages/index1.vue') 
    },
  ]
})