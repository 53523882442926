import Vue from 'vue'
import App from './App.vue'
// 全部引入elementUI
import ElementUI from 'element-ui' // 引入elementUI
import 'element-ui/lib/theme-chalk/index.css'

import router from './router' // 路由
// import api from './api' // 导入api接口
import axios from 'axios'
import qs from 'qs'; // 根据需求是否导入qs模块

import baseUrl from './api/base';





// 全部引入elementUI
Vue.use(ElementUI)



// Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$baseUrl = baseUrl;


Vue.config.productionTip = false  // 关闭生产模式下给出的提示

new Vue({
  render: h => h(App), // h函数会生成一个VNode节点，render函数得到这个VNode节点之后，返回给Vue.js的mount函数渲染成真实DOM节点，并挂载到根节点上。
  router,
}).$mount('#app')
