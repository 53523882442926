<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      res: {}
    };
  },
  mounted() {
    
  },
  methods: {}
};
</script>

<style lang="scss">
</style>
